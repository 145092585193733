import type DataGridControl from './DataGridControl.ts';
import type FilterObject from 'o365-filterobject';
import { filterUtils } from 'o365-filterobject';
import { ref, computed, watch } from 'vue';

/**
 * Composable for addding sorting and filtering on any array ref
 */
export default function useArrayData(dataRef: Ref<any[]>, gridControl: Ref<DataGridControl>) {
    //--- Filter ---
    let filteredRef = dataRef;
    if ((!gridControl.value.props.disableFilterRow || gridControl.value.props.isLookup) && !gridControl.value.props.noHeader) {
        const { filteredData } = useArrayFilter(dataRef, {
            filterObject: gridControl.value.filterObject,
            updateFilterRowCount: (newCount) => {gridControl.value.utils.filteredRowCount = newCount; }
        });
        filteredRef = filteredData;
    }

    //--- Sort ---
    let sortedRef = filteredRef;
    if (!gridControl.value.props.disableSorting) {
        sortedRef = ref([...filteredRef.value]);
        const sortedArray = computed(() => {
            return [...filteredRef.value].sort();
        });

        const applySort = () => {
            const sortOrder = gridControl.value.dataColumns.columns.filter(x => x.sort != null);

            if (sortOrder.length > 0) {
                let sortResult = [...filteredRef.value];
                sortOrder.sort((a, b) => b.sortOrder - a.sortOrder);
                sortOrder.forEach(item => {
                    sortResult.sort(sortByKey(item.field, item.sort, item.type));
                });
                sortedRef.value = sortResult;
            } else {
                sortedRef.value = filteredRef.value;
            }
        };

        watch(sortedArray, () => {
            applySort();
        });

        gridControl.value.utils.applySort = applySort;

        const sortByKey = (key: string, order: 'asc' | 'desc', type: string = 'string') => {
            return function (a: any, b: any) {
                var a = a[key];
                var b = b[key];
                if (type === "string") {
                    if (a && b) {
                        if (a !== null) a = a.toString().toUpperCase();
                        if (b !== null) b = b.toString().toUpperCase();
                    }
                } else if (['datetime', 'date'].includes(type)) {
                    a = new Date(a);
                    b = new Date(b);
                }

                switch (type) {
                    case "number":
                        return order === "asc" ? (a - b) : (b - a);

                    case "string":
                    case "date":
                    case "datetime":
                    case "uniqueidentifier":
                        return order === "asc" ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);

                    default:
                        return 0;
                }
            };
        }
    }

    watch(() => dataRef.value.length, (newLength: number) => {
        gridControl.value.utils.rowCount = newLength;
        if (gridControl.value.props.selectFirstRowOnLoad && gridControl.value.state.currentIndex == null && newLength) {
            gridControl.value.setCurrentIndex(0);
        }
    });

    gridControl.value.utils.rowCount = dataRef.value.length; 

    gridControl.value.utils.processedData = sortedRef;

    return sortedRef;
}

export function useArrayFilter(dataRef: Ref<any[]>, options: {
    filterObject: FilterObject,
    updateFilterRowCount?: (newCount: number) => void
}) {
    const resultData: Ref<any[]> = ref([...dataRef.value]);
    const sortedArray = computed(() => {
        return [...dataRef.value].sort();
    });

    const applyFilter = async () => {
        resultData.value = filterUtils.applyFilterObject(dataRef.value, options.filterObject.filterObject) ?? [];
        if (options.updateFilterRowCount) {
            options.updateFilterRowCount(resultData.value.length);
        }
    };

    watch(sortedArray, () => {
        applyFilter();
    });

    options.filterObject.dataObject.load = applyFilter;
    return {
        filteredData: computed(() => resultData.value),
        applyFilter,
    };
}

interface Ref<T> { value: T };